import { environment } from '@env/environment';
import { SuperStrapiModel } from './super-strapi.model';
import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';

import { Typology } from './typology.model';
import { Suit } from './suit.model';
import { Image } from './image.model';
import { Color } from './color.model';
import { Folder } from './folder.model';
import { ArchiveLoan } from './archive-loan.model';
import { Material } from './material.model';
import { Technique } from './technique.model';
import { IArchiveImageGallery } from '@interfaces';
import { Season } from './season.model';
import { Brand } from './brand.model';
import { ConservationStatus } from './conservation-status.model';
import { Fantasy } from './fantasy.model';
import { Apartment } from './apartment.model';
import { Place } from './place.model';
import { Shelf } from './shelf.model';
import { Sector } from './sector.model';
import { Box } from './box.model';
import { Detail } from './detail.model';
import { Director } from './director.model';
import { Format } from './format.model';
import { Language } from './language.model';
import { EArchiveType } from '@web/enums';
import { Distributor } from './distributor.model';
import { Producer } from './producer.model';
import { Race } from './race.model';
import { Sex } from './sex.model';
import { ArchiveSale } from './archive-sale.model';
import { Sale } from './sale.model';
import { Lot } from './lot.model';

export class Archive extends SuperStrapiModel<Archive> {
  // COMPUTED
  inventory: string;
  description: string;

  // FLAT VALUES
  year: string;
  fantasy_details: string;
  conservation_status_details: string;
  note: string;
  value: number;
  size: string;
  quantity: number;
  object_description: string;
  is_hanging: boolean;
  is_sold: boolean;
  is_visible_to_editor: boolean;
  is_visible_to_collaborator: boolean;
  is_visible_to_ufficio_stile: boolean;
  is_visible_to_viewer: boolean;

  // ARRAYS
  t_1s: Typology[];
  t_2s: Typology[];
  t_3s: Typology[];
  t_4s: Typology[];
  images: Image[];
  folders: Folder[];
  archive_loans: ArchiveLoan[];
  archive_sales: ArchiveSale[];
  links_from: Archive[];
  links_to: Archive[];
  lookbooks_from: Archive[];
  lookbooks_to: Archive[];
  external_links: string[];

  suit: Suit;
  sale: Sale;
  mainColor: Color;
  colors: Color[];
  mainMaterial: Material;
  materials: Material[];
  techniques: Technique[];
  season: Season;
  brand: Brand;
  conservation_status: ConservationStatus;
  fantasy: Fantasy;
  details: Detail[];

  apartment: Apartment;
  place: Place;
  shelf: Shelf;
  sector: Sector;
  box: Box;

  // FASHION
  n_pieces: number;
  market_estimate: number;
  market_estimate_date: Date;

  // MULTIMEDIA
  title: string;
  director: Director;
  cast: string;
  plot: string;
  format: Format;
  language: Language;

  // CELLAR
  distributor: Distributor;
  producer: Producer;
  race: Race;
  sex: Sex;
  purchase_date: Date;
  transfer_date: Date;
  production_date: Date;
  seasoning_date: Date;
  weight: number;
  purchase_price_kg: number;
  lot: Lot;
  grab_weight: number;
  grab_date: Date;
  selectedGrabWeight;

  // MISC
  galleryImageIndex = 0;
  selectedQuantity = 1;

  get links(): Archive[] {
    return [...this.links_from, ...this.links_to];
  }

  get lookbooks(): Archive[] {
    return [...this.lookbooks_from, ...this.lookbooks_to];
  }

  get suitItems(): Archive[] {
    return this.suit?.archives?.filter(archive => archive.id !== this.id) || [];
  }

  get isInLoanOut(): boolean {
    if (!this.archive_loans || !this.archive_loans.length) {
      return false;
    }
    return !!this.archive_loans.find(archiveLoan => archiveLoan.isOut);
  }

  get loanOut(): ArchiveLoan {
    return this.archive_loans.find(archiveLoan => archiveLoan.isOut);
  }

  get typologies_1() {
    return this.t_1s.map(t => t.name).join(', ');
  }

  get typologies_2() {
    return this.t_2s.map(t => t.name).join(', ');
  }

  get typologies_3() {
    return this.t_3s.map(t => t.name).join(', ');
  }

  get typologies_4() {
    return this.t_4s.map(t => t.name).join(', ');
  }

  get typologies_tree() {
    return `${this.typologies_1}${this.typologies_2 ? ' -> ' + this.typologies_2 : ''}${
      this.typologies_3 ? ' -> ' + this.typologies_3 : ''
    }${this.typologies_4 ? ' -> ' + this.typologies_4 : ''}`;
  }

  get gallery(): IArchiveImageGallery[] {
    const imageGallery: IArchiveImageGallery[] = [];

    // NOTE: Add the item images
    if (this.images?.length) {
      imageGallery.push(...this.images.map(image => ({ image, type: 'item' })));
    } else {
      imageGallery.push({ image: new Image(), type: 'item' });
    }

    // NOTE: Add the item's lookbooks_to
    if (this.lookbooks_to?.length) {
      this.lookbooks_to.forEach(lookbook => {
        if (lookbook.images?.length) {
          imageGallery.push(
            ...(lookbook.images?.map(image => ({
              image,
              type: 'lookbooks_to',
              inventory: lookbook.inventory,
            })) || [])
          );
        } else {
          imageGallery.push({
            image: new Image(),
            type: 'lookbooks_to',
            inventory: lookbook.inventory,
          });
        }
      });
    }

    // NOTE: Add the item's lookbooks_from
    if (this.lookbooks_from?.length) {
      this.lookbooks_from.forEach(lookbook => {
        if (lookbook.images?.length) {
          imageGallery.push(
            ...(lookbook.images?.map(image => ({
              image,
              type: 'lookbooks_from',
              inventory: lookbook.inventory,
            })) || [])
          );
        } else {
          imageGallery.push({
            image: new Image(),
            type: 'lookbooks_from',
            inventory: lookbook.inventory,
          });
        }
      });
    }

    // NOTE: Add the item's links images
    if (this.links?.length) {
      this.links.forEach(link => {
        if (link.images?.length) {
          imageGallery.push(
            ...(link.images?.map(image => ({
              image,
              type: 'link',
              inventory: link.inventory,
            })) || [])
          );
        } else {
          imageGallery.push({
            image: new Image(),
            type: 'link',
            inventory: link.inventory,
          });
        }
      });
    }

    return imageGallery;
  }

  get qrCodeUrl(): string {
    return `${environment.appUrl}qr-code?inventory=${this.inventory}`;
  }

  get fullLocation(): string {
    return `${this?.apartment?.name || ''}${this?.place?.name ? ', ' + this.place.name : ''}${
      this?.shelf?.name ? ', ' + this.shelf.name : ''
    }${this?.sector?.name ? ', ' + this.sector.name : ''}${this?.box?.name ? ', ' + this.box.name : ''}`;
  }

  constructor(data?: any, modelInstance?: Partial<Archive>) {
    super(data, modelInstance);
    if (modelInstance) {
      if (modelInstance?.brand) {
        this.brand =
          modelInstance.brand['metaData'] ||
          new Brand({ id: modelInstance.brand?.id, attributes: { ...(modelInstance.brand ?? {}) } });
      }
      if (modelInstance?.mainColor) {
        this.mainColor =
          modelInstance.mainColor['metaData'] ||
          new Color({ id: modelInstance.mainColor?.id, attributes: { ...(modelInstance.mainColor ?? {}) } });
      }
      if (modelInstance?.colors) {
        this.colors = modelInstance.colors.map(
          color => color['metaData'] || new Color({ id: color?.id, attributes: { ...(color ?? {}) } })
        );
      }
      if (modelInstance?.mainMaterial) {
        this.mainMaterial =
          modelInstance.mainMaterial['metaData'] ||
          new Material({
            id: modelInstance.mainMaterial?.id,
            attributes: { ...(modelInstance.mainMaterial ?? {}) },
          });
      }
      if (modelInstance?.materials) {
        this.materials = modelInstance.materials.map(
          material =>
            material['metaData'] || new Material({ id: material?.id, attributes: { ...(material ?? {}) } })
        );
      }
      if (modelInstance?.techniques) {
        this.techniques = modelInstance.techniques.map(
          technique =>
            technique['metaData'] ||
            new Technique({ id: technique?.id, attributes: { ...(technique ?? {}) } })
        );
      }
      if (modelInstance?.details) {
        this.details = modelInstance.details.map(
          detail => detail['metaData'] || new Detail({ id: detail?.id, attributes: { ...(detail ?? {}) } })
        );
      }

      if (modelInstance?.distributor) {
        this.distributor =
          modelInstance.distributor['metaData'] ||
          new Distributor({
            id: modelInstance.distributor?.id,
            attributes: { ...(modelInstance.distributor ?? {}) },
          });
      }
      if (modelInstance?.producer) {
        this.producer =
          modelInstance.producer['metaData'] ||
          new Producer({ id: modelInstance.producer?.id, attributes: { ...(modelInstance.producer ?? {}) } });
      }
      if (modelInstance?.race) {
        this.race =
          modelInstance.race['metaData'] ||
          new Race({ id: modelInstance.race?.id, attributes: { ...(modelInstance.race ?? {}) } });
      }
      if (modelInstance?.sex) {
        this.sex =
          modelInstance.sex['metaData'] ||
          new Sex({ id: modelInstance.sex?.id, attributes: { ...(modelInstance.sex ?? {}) } });
      }
      if (modelInstance?.lot) {
        this.lot =
          modelInstance.lot['metaData'] ||
          new Lot({ id: modelInstance.lot?.id, attributes: { ...(modelInstance.lot ?? {}) } });
      }
      return;
    }

    if (!data?.attributes?.inventory) {
      this.inventory = undefined;
    }
    if (!data?.attributes?.description) {
      this.description = undefined;
    }
    if (!data?.attributes?.year) {
      this.year = undefined;
    }
    if (!data?.attributes?.fantasy_details) {
      this.fantasy_details = undefined;
    }
    if (!data?.attributes?.conservation_status_details) {
      this.conservation_status_details = undefined;
    }
    if (!data?.attributes?.note) {
      this.note = undefined;
    }
    if (!Number.isFinite(data?.attributes?.value)) {
      this.value = undefined;
    }
    if (!Number.isFinite(data?.attributes?.market_estimate)) {
      this.market_estimate = undefined;
    }
    if (data?.attributes?.market_estimate_date) {
      this.market_estimate_date = new Date(data.attributes?.market_estimate_date);
    }
    if (!data?.attributes?.size) {
      this.size = undefined;
    }
    if (!Number.isFinite(data?.attributes?.quantity)) {
      this.quantity = undefined;
    }
    if (!Number.isFinite(data?.attributes?.n_pieces)) {
      this.n_pieces = undefined;
    }
    if (!data?.attributes?.object_description) {
      this.object_description = undefined;
    }
    if (!data?.attributes?.external_links) {
      this.external_links = [];
    }
    if (data?.attributes?.is_hanging === null) {
      this.is_hanging = undefined;
    }
    if (data?.attributes?.is_sold === null) {
      this.is_sold = undefined;
    }
    if (data?.attributes?.is_visible_to_editor === null) {
      this.is_visible_to_editor = undefined;
    }
    if (data?.attributes?.is_visible_to_collaborator === null) {
      this.is_visible_to_collaborator = undefined;
    }
    if (data?.attributes?.is_visible_to_ufficio_stile === null) {
      this.is_visible_to_ufficio_stile = undefined;
    }
    if (data?.attributes?.is_visible_to_viewer === null) {
      this.is_visible_to_viewer = undefined;
    }

    if (data?.attributes?.t_1s) {
      this.t_1s = data.attributes.t_1s.data.map(item => new Typology(item));
    } else {
      this.t_1s = [];
    }
    if (data?.attributes?.t_2s) {
      this.t_2s = data.attributes.t_2s.data.map(item => new Typology(item));
    } else {
      this.t_2s = [];
    }
    if (data?.attributes?.t_3s) {
      this.t_3s = data.attributes.t_3s.data.map(item => new Typology(item));
    } else {
      this.t_3s = [];
    }
    if (data?.attributes?.t_4s) {
      this.t_4s = data.attributes.t_4s.data.map(item => new Typology(item));
    } else {
      this.t_4s = [];
    }

    if (data?.attributes?.suit?.data) {
      this.suit = new Suit(data.attributes.suit.data);
    } else {
      this.suit = undefined;
    }

    if (data?.attributes?.sale?.data) {
      this.sale = new Sale(data.attributes.sale.data);
    } else {
      this.sale = undefined;
    }

    if (data?.attributes?.images?.data.length) {
      this.images = data.attributes.images.data.map(image => new Image(image));
    } else {
      this.images = [];
    }

    if (data?.attributes?.mainColor?.data) {
      this.mainColor = new Color(data.attributes.mainColor.data);
    } else {
      this.mainColor = undefined;
    }

    if (data?.attributes?.colors) {
      this.colors = data.attributes.colors.data.map(color => new Color(color));
    } else {
      this.colors = [];
    }

    if (data?.attributes?.mainMaterial?.data) {
      this.mainMaterial = new Material(data.attributes.mainMaterial.data);
    } else {
      this.mainMaterial = undefined;
    }

    if (data?.attributes?.materials) {
      this.materials = data.attributes.materials.data.map(material => new Material(material));
    } else {
      this.materials = [];
    }

    if (data?.attributes?.techniques) {
      this.techniques = data.attributes.techniques.data.map(technique => new Technique(technique));
    } else {
      this.techniques = [];
    }

    if (data?.attributes?.details) {
      this.details = data.attributes.details.data.map(detail => new Detail(detail));
    } else {
      this.details = [];
    }

    if (data?.attributes?.season?.data) {
      this.season = new Season(data.attributes.season.data);
    } else {
      this.season = undefined;
    }
    if (data?.attributes?.brand?.data) {
      this.brand = new Brand(data.attributes.brand.data);
    } else {
      this.brand = undefined;
    }

    if (data?.attributes?.conservation_status?.data) {
      this.conservation_status = new ConservationStatus(data.attributes.conservation_status.data);
    } else {
      this.conservation_status = undefined;
    }

    if (data?.attributes?.fantasy?.data) {
      this.fantasy = new Fantasy(data.attributes.fantasy.data);
    } else {
      this.fantasy = undefined;
    }

    if (data?.attributes?.apartment?.data) {
      this.apartment = new Apartment(data.attributes.apartment.data);
    } else {
      this.apartment = undefined;
    }

    if (data?.attributes?.place?.data) {
      this.place = new Place(data.attributes.place.data);
    } else {
      this.place = undefined;
    }

    if (data?.attributes?.shelf?.data) {
      this.shelf = new Shelf(data.attributes.shelf.data);
    } else {
      this.shelf = undefined;
    }

    if (data?.attributes?.sector?.data) {
      this.sector = new Sector(data.attributes.sector.data);
    } else {
      this.sector = undefined;
    }

    if (data?.attributes?.box?.data) {
      this.box = new Box(data.attributes.box.data);
    } else {
      this.box = undefined;
    }

    if (data?.attributes?.folders) {
      this.folders = data.attributes.folders.data.map(folder => new Folder(folder));
    } else {
      this.folders = [];
    }

    if (data?.attributes?.archive_loans) {
      this.archive_loans = data.attributes.archive_loans.data.map(
        archiveLoan => new ArchiveLoan(archiveLoan)
      );
    } else {
      this.archive_loans = [];
    }

    if (data?.attributes?.archive_sales) {
      this.archive_sales = data.attributes.archive_sales.data.map(
        archiveSale => new ArchiveSale(archiveSale)
      );
    } else {
      this.archive_sales = [];
    }

    if (data?.attributes?.links_from) {
      this.links_from = data.attributes.links_from.data.map(archive => new Archive(archive));
    } else {
      this.links_from = [];
    }
    if (data?.attributes?.links_to) {
      this.links_to = data.attributes.links_to.data.map(archive => new Archive(archive));
    } else {
      this.links_to = [];
    }
    if (data?.attributes?.lookbooks_from) {
      this.lookbooks_from = data.attributes.lookbooks_from.data.map(archive => new Archive(archive));
    } else {
      this.lookbooks_from = [];
    }
    if (data?.attributes?.lookbooks_to) {
      this.lookbooks_to = data.attributes.lookbooks_to.data.map(archive => new Archive(archive));
    } else {
      this.lookbooks_to = [];
    }

    if (data?.attributes?.format?.data) {
      this.format = new Format(data.attributes.format.data);
    } else {
      this.format = undefined;
    }
    if (data?.attributes?.language?.data) {
      this.language = new Language(data.attributes.language.data);
    } else {
      this.language = undefined;
    }
    if (data?.attributes?.director?.data) {
      this.director = new Director(data.attributes.director.data);
    } else {
      this.director = undefined;
    }
    if (!data?.attributes?.title) {
      this.title = undefined;
    }
    if (!data?.attributes?.cast) {
      this.cast = undefined;
    }
    if (!data?.attributes?.plot) {
      this.plot = undefined;
    }

    if (data?.attributes?.distributor?.data) {
      this.distributor = new Distributor(data.attributes.distributor.data);
    } else {
      this.distributor = undefined;
    }
    if (data?.attributes?.producer?.data) {
      this.producer = new Producer(data.attributes.producer.data);
    } else {
      this.producer = undefined;
    }
    if (data?.attributes?.race?.data) {
      this.race = new Race(data.attributes.race.data);
    } else {
      this.race = undefined;
    }
    if (data?.attributes?.sex?.data) {
      this.sex = new Sex(data.attributes.sex.data);
    } else {
      this.sex = undefined;
    }
    if (data?.attributes?.lot?.data) {
      this.lot = new Lot(data.attributes.lot.data);
    } else {
      this.lot = undefined;
    }
    if (data?.attributes?.purchase_date) {
      this.purchase_date = new Date(data.attributes?.purchase_date);
    }
    if (data?.attributes?.transfer_date) {
      this.transfer_date = new Date(data.attributes?.transfer_date);
    }
    if (data?.attributes?.production_date) {
      this.production_date = new Date(data.attributes?.production_date);
    }
    if (data?.attributes?.seasoning_date) {
      this.seasoning_date = new Date(data.attributes?.seasoning_date);
    }
    if (!Number.isFinite(data?.attributes?.weight)) {
      this.weight = undefined;
    }
    if (!Number.isFinite(data?.attributes?.purchase_price_kg)) {
      this.purchase_price_kg = undefined;
    }
    if (!Number.isFinite(data?.attributes?.grab_weight)) {
      this.grab_weight = undefined;
    }
    if (data?.attributes?.grab_date) {
      this.grab_date = new Date(data.attributes?.grab_date);
    }
  }

  static concatArchive(sourceArchive: Archive, concatData: Partial<Archive>): Archive {
    // these are array chainable fields
    const chainableArrayProps = [
      't_1s',
      't_2s',
      't_3s',
      't_4s',
      'colors',
      'materials',
      'techniques',
      'details',
    ];
    chainableArrayProps.forEach(key => {
      if (Array.isArray(concatData[key]) && concatData[key].length) {
        sourceArchive[key] = sourceArchive[key] || [];
        sourceArchive[key] = [...new Set([...concatData[key], ...sourceArchive[key]]).values()];
      }
    });

    // concat notes
    if (concatData.note) {
      sourceArchive.note = sourceArchive.note
        ? `${concatData.note} | ${sourceArchive.note}`
        : concatData.note;
    }

    //merge all other fields
    for (const key in concatData) {
      if (concatData.hasOwnProperty(key) && !chainableArrayProps.includes(key) && key !== 'note') {
        sourceArchive[key] = concatData[key];
      }
    }

    return sourceArchive;
  }

  // NOTE: We need this otherwise an empty array will remove all relations and null data will delete values
  static getArchiveForBulkUpdate(archive: Archive): Partial<Archive> {
    const bulkUpdateArchive: Partial<Archive> = {};
    for (const key in archive) {
      if (archive.hasOwnProperty(key)) {
        const prop: any = archive[key];
        if (Array.isArray(prop)) {
          if (prop.length) {
            bulkUpdateArchive[key as any] = archive[key];
          }
        } else if (archive[key] !== null && archive[key] !== undefined) {
          bulkUpdateArchive[key as any] = archive[key];
        }
      }
    }
    if (bulkUpdateArchive.t_1s) {
      bulkUpdateArchive.t_2s ||= [];
      bulkUpdateArchive.t_3s ||= [];
      bulkUpdateArchive.t_4s ||= [];
    }
    return bulkUpdateArchive;
  }

  static getArchiveForBulkUpdateFromPartial(
    archive: Archive,
    partialArchive: Partial<Archive>
  ): Partial<Archive> {
    for (const key in archive) {
      if (key != 'id' && !partialArchive.hasOwnProperty(key)) {
        delete archive[key];
      }
    }
    return archive;
  }

  // We do not include readonly fields like inventory
  get serialised() {
    const returnObj = {
      id: this.id,
      note: this.note,
      value: this.getValue(),
      description: this.getDescription() || undefined,
      quantity: typeof this.quantity === 'number' ? Number(this.quantity) : this.quantity,
      is_sold: this.is_sold,
      sale: !!this.sale ? this.sale.serialised.id : this.sale,
      is_visible_to_editor: this.is_visible_to_editor,
      is_visible_to_collaborator: this.is_visible_to_collaborator,
      is_visible_to_ufficio_stile: this.is_visible_to_ufficio_stile,
      is_visible_to_viewer: this.is_visible_to_viewer,

      t_1s: !!this.t_1s ? this.t_1s.filter(item => !!item).map(item => item.serialised.id) : this.t_1s,
      t_2s: !!this.t_2s ? this.t_2s.filter(item => !!item).map(item => item.serialised.id) : this.t_2s,
      t_3s: !!this.t_3s ? this.t_3s.filter(item => !!item).map(item => item.serialised.id) : this.t_3s,
      t_4s: !!this.t_4s ? this.t_4s.filter(item => !!item).map(item => item.serialised.id) : this.t_4s,

      apartment: !!this.apartment ? this.getNullIfIdNotValid(this.apartment.serialised.id) : this.apartment,
      place: !!this.place ? this.getNullIfIdNotValid(this.place.serialised.id) : this.place,
      shelf: !!this.shelf ? this.getNullIfIdNotValid(this.shelf.serialised.id) : this.shelf,
      sector: !!this.sector ? this.getNullIfIdNotValid(this.sector.serialised.id) : this.sector,
      box: !!this.box ? this.getNullIfIdNotValid(this.box.serialised.id) : this.box,

      images: this.images ? this.images.map(item => item.serialised.id) : this.images,
      folders: !!this.folders ? this.folders.map(item => item.serialised.id) : this.folders,
      archive_loans: !!this.archive_loans
        ? this.archive_loans.map(item => item.serialised.id)
        : this.archive_loans,
      archive_sales: !!this.archive_sales
        ? this.archive_sales.map(item => item.serialised.id)
        : this.archive_sales,
    };

    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        return {
          ...returnObj,
          year: this.year,
          conservation_status_details: this.conservation_status_details,
          fantasy_details: this.fantasy_details,
          size: this.size,
          object_description: this.object_description,
          external_links: this.external_links,
          is_hanging: this.is_hanging,
          market_estimate: this.market_estimate,
          market_estimate_date: this.market_estimate_date && bitfFormatDateISO(this.market_estimate_date),
          n_pieces: typeof this.n_pieces === 'number' ? Number(this.n_pieces) : this.n_pieces,

          mainColor: !!this.mainColor ? this.mainColor.serialised.id : this.mainColor,
          colors: !!this.colors ? this.colors.map(item => item.serialised.id) : this.colors,
          mainMaterial: !!this.mainMaterial ? this.mainMaterial.serialised.id : this.mainMaterial,
          materials: !!this.materials ? this.materials.map(item => item.serialised.id) : this.materials,
          techniques: !!this.techniques ? this.techniques.map(item => item.serialised.id) : this.techniques,
          details: !!this.details ? this.details.map(item => item.serialised.id) : this.details,

          season: !!this.season ? this.getNullIfIdNotValid(this.season.serialised.id) : this.season,
          brand: !!this.brand ? this.brand.serialised.id : this.brand,
          conservation_status: !!this.conservation_status
            ? this.getNullIfIdNotValid(this.conservation_status.serialised.id)
            : this.conservation_status,
          fantasy: !!this.fantasy ? this.getNullIfIdNotValid(this.fantasy.serialised.id) : this.fantasy,

          suit: !!this.suit ? this.suit.serialised.id : this.suit,
          links_from: !!this.links_from ? this.links_from.map(item => item.serialised.id) : this.links_from,
          links_to: !!this.links_to ? this.links_to.map(item => item.serialised.id) : this.links_to,
          lookbooks_from: !!this.lookbooks_from
            ? this.lookbooks_from.map(item => item.serialised.id)
            : this.lookbooks_from,
          lookbooks_to: !!this.lookbooks_to
            ? this.lookbooks_to.map(item => item.serialised.id)
            : this.lookbooks_to,
        };
      case EArchiveType.MULTIMEDIA:
        return {
          ...returnObj,
          year: this.year,
          conservation_status_details: this.conservation_status_details,
          size: this.size,
          object_description: this.object_description,
          external_links: this.external_links,
          title: this.title,
          cast: this.cast,
          plot: this.plot,

          director: !!this.director ? this.getNullIfIdNotValid(this.director.serialised.id) : this.director,
          format: !!this.format ? this.getNullIfIdNotValid(this.format.serialised.id) : this.format,
          language: !!this.language ? this.getNullIfIdNotValid(this.language.serialised.id) : this.language,
        };
      case EArchiveType.CELLAR:
        return {
          ...returnObj,
          purchase_date: this.purchase_date && bitfFormatDateISO(this.purchase_date),
          transfer_date: this.transfer_date && bitfFormatDateISO(this.transfer_date),
          production_date: this.production_date && bitfFormatDateISO(this.production_date),
          seasoning_date: this.getSeasoningDate(),
          weight: typeof this.weight === 'number' ? Number(this.weight) : this.weight,
          purchase_price_kg:
            typeof this.purchase_price_kg === 'number'
              ? Number(this.purchase_price_kg)
              : this.purchase_price_kg,

          distributor: !!this.distributor ? this.distributor.serialised.id : this.distributor,
          producer: !!this.producer ? this.producer.serialised.id : this.producer,
          race: !!this.race ? this.race.serialised.id : this.race,
          sex: !!this.sex ? this.sex.serialised.id : this.sex,
          lot: !!this.lot ? this.lot.serialised.id : this.lot,
          grab_weight: typeof this.grab_weight === 'number' ? Number(this.grab_weight) : this.grab_weight,
          grab_date: this.grab_date && bitfFormatDateISO(this.grab_date),
        };
    }
    return returnObj;
  }

  private getNullIfIdNotValid(id: number) {
    return id !== -1 ? id : null;
  }

  private getValue() {
    let value = typeof this.value === 'number' ? Number(this.value) : this.value;

    switch (environment.archiveType) {
      case EArchiveType.CELLAR:
        if (!value && this.weight && this.purchase_price_kg) {
          return (this.weight / 1000) * this.purchase_price_kg;
        }
        return value;
      default:
        return value;
    }
  }

  private getDescription = () => {
    let typology: Typology;
    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        typology = this.t_4s?.[0] || this.t_3s?.[0] || this.t_2s?.[0] || this.t_1s?.[0];
        return `${this.brand ? this.brand.name + ', ' : ''}${typology ? typology.name + ', ' : ''}${
          this.year ? this.year + ', ' : ''
        }${this.season ? this.season.name + ', ' : ''}`.slice(0, -2);

      case EArchiveType.MULTIMEDIA:
        typology = this.t_2s?.[0] || this.t_1s?.[0];
        return `${this.title ? this.title + ', ' : ''}${typology ? typology.name + ', ' : ''}${
          this.year ? this.year + ', ' : ''
        }`.slice(0, -2);

      case EArchiveType.CELLAR:
        typology = this.t_4s?.[0] || this.t_3s?.[0] || this.t_2s?.[0] || this.t_1s?.[0];
        return `${this.producer ? this.producer.name + ', ' : ''}${
          this.distributor ? this.distributor.name + ', ' : ''
        }${typology ? typology.name + ', ' : ''}${this.race ? this.race.name + ', ' : ''}${
          this.weight ? (this.weight / 1000).toFixed(1) + ' Kg, ' : ''
        }`.slice(0, -2);
    }
  };

  private getSeasoningDate = () => {
    switch (environment.archiveType) {
      case EArchiveType.CELLAR:
        if (this.seasoning_date) {
          return bitfFormatDateISO(this.seasoning_date);
        } else {
          if (this.race?.seasoning_months && this.production_date) {
            const seasoningDate = new Date(this.production_date);
            seasoningDate.setMonth(this.production_date.getMonth() + this.race.seasoning_months);
            return bitfFormatDateISO(seasoningDate);
          }
        }
        return undefined;
      default:
        return undefined;
    }
  };
}
